<template>
  <v-card flat tile v-if="!loading && eventos">
    <v-card-title class="lightgray">
      <v-text-field
        style="max-width: 400px;"
        v-model="search"
        append-icon="search"
        label="Buscar"
        clearable
        outlined
        solo
        flat
        dense
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <template v-if="eventos">
        <v-data-table
          :headers="headers"
          :search="search"
          :items="eventos"
          :items-per-page="10"
          :sort-by="['created_at']"
          :sort-desc="true"
          class="data-tables data-tables__row-click"
          @click:row="goToEvento"
        >
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | moment("DD/MM/YYYY") }}
          </template>

          <template v-slot:item.cliente="{ item }">
            {{ excerpt(item.cliente) }}
          </template>

          <template v-slot:item.classificacao="{ item }">
            <v-chip
              class="ma-2"
              small
              :color="item.cor"
              label
              text-color="white"
            >
              <v-icon size="22" left>{{ item.icone }}</v-icon>
              {{ item.classificacao }}
            </v-chip>
          </template>

          <template v-slot:item.status="{ item }">
            {{ getStatus(item.status) }}
          </template>
        </v-data-table>
      </template>
    </v-card-text>
  </v-card>
  <v-card flat tile v-else class="pa-8">
    <Carregando />
  </v-card>
</template>

<script>
import { getEventos } from "@/services/api/eventos.api.js";
import { mapActions, mapState } from "vuex";

export default {
  name: "ListaEventos",
  props: ["clienteId"],
  data: () => ({
    loading: false,
    eventos: null,
    search: "",
    headers: [
      {
        text: "Data",
        value: "created_at",
      },
      {
        text: "Protocolo",
        value: "protocolo",
      },
      {
        text: "Cliente",
        value: "cliente",
      },
      {
        text: "Classificacao",
        value: "classificacao",
      },
      {
        text: "Tipo",
        value: "tipoevento",
      },
      {
        text: "Status",
        value: "status",
      },
    ],
  }),
  computed: {
    ...mapState("Eventos", {
      status: (state) => state.status,
    }),
    // url() {
    //   let queryString = "";
    //   for (let key in this.$route.query) {
    //     queryString += `&${key}=${this.$route.query[key]}`;
    //   }
    //   return `?${queryString}`;
    // },
  },
  watch: {
    // url() {
    //   if (this.$route.path === "/eventos") {
    //     this.buscaEventos();
    //   }
    // },
  },
  methods: {
    ...mapActions("Eventos", {
      reset: "reset",
    }),
    buscaEventos() {
      this.$Progress.start();

      getEventos(`?cliente=${this.clienteId}`)
        .then((response) => {
          this.eventos = response;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$Progress.fail();
          console.log(error);
        });
    },
    excerpt(text) {
      if (!text) return;
      if (text.length > 25) {
        return text.substr(0, 25) + "...";
      } else {
        return text;
      }
    },
    getStatus(id) {
      if (this.status) {
        let find = this.status.find((item) => item.value === id);
        if (find) {
          return find.text;
        }
      }
    },
    goToEvento(item) {
      this.$router.push({
        path: `/clientes/${this.clienteId}/evento/${item.id}`,
      });
    },
  },
  beforeDestroy() {
    this.reset();
  },
  updated() {},
  created() {
    this.buscaEventos();
  },
};
</script>

<style scoped lang="scss"></style>
