var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && _vm.eventos)?_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-title',{staticClass:"lightgray"},[_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"search","label":"Buscar","clearable":"","outlined":"","solo":"","flat":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-card-text',[(_vm.eventos)?[_c('v-data-table',{staticClass:"data-tables data-tables__row-click",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.eventos,"items-per-page":10,"sort-by":['created_at'],"sort-desc":true},on:{"click:row":_vm.goToEvento},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD/MM/YYYY"))+" ")]}},{key:"item.cliente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.excerpt(item.cliente))+" ")]}},{key:"item.classificacao",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":item.cor,"label":"","text-color":"white"}},[_c('v-icon',{attrs:{"size":"22","left":""}},[_vm._v(_vm._s(item.icone))]),_vm._v(" "+_vm._s(item.classificacao)+" ")],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")]}}],null,false,795151197)})]:_vm._e()],2)],1):_c('v-card',{staticClass:"pa-8",attrs:{"flat":"","tile":""}},[_c('Carregando')],1)}
var staticRenderFns = []

export { render, staticRenderFns }